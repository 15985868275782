<template>
  <div class="turntable-prize">
    <template>
      <van-list
        v-model="loading"
        :finished="finished"
        :finished-text="isFinish && list.length == 0 ? '' : '没有更多了'"
        @load="onLoad"
      >
        <template v-if="isFinish && list.length > 0">
          <van-cell
            v-for="(item, index) in list"
            :key="index"
            :title="item.reward"
            :label="item.createTime"
          />
        </template>
      </van-list>
    </template>
    <template v-if="isFinish && list.length == 0">
      <van-empty
        class="custom-image"
        :image="require('./imgs/empty_img.png')"
        description="还没有获得奖品哦"
      />
      <div
        class="prize-btn u-flex u-row-center u-col-center"
        @click="$router.go(-1)"
      >
        去抽奖
      </div>
    </template>
  </div>
</template>

<script>
import { luckUserList } from "@/api/activity/turntable";

export default {
  name: "TurntablePrize",
  data() {
    return {
      // 是否加载完成
      isFinish: false,
      list: [],
      loading: false,
      finished: false,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  methods: {
    onLoad() {
      luckUserList(this.params).then((res) => {
        this.isFinish = true;
        if (200 === res.code) {
          if (res.data.list.length > 0) {
            this.total = res.data.total;
            this.params.pageNum++;

            this.list.push(...res.data.list);

            this.loading = false;
            if (this.list.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.finished = true;
          }
        } else {
          this.$toast.fail("获取列表失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.turntable-prize {
  /deep/ {
    .van-cell {
      font-size: 0.32rem;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      color: rgba(35, 45, 65, 0.99);
      line-height: 0.44rem;
      padding-top: 0.3rem;
      padding-bottom: 0.3rem;
    }

    .van-cell__label {
      font-size: 0.24rem;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: #868e9e;
      line-height: 0.34rem;
    }
  }
}

.custom-image {
  /deep/ {
    .van-empty__image {
      width: 5.1rem;
      height: 3.08rem;
    }
  }
}

.prize-btn {
  width: 4.2rem;
  height: 0.96rem;
  background: #eb3f35;
  border-radius: 0.54rem;
  font-size: 0.32rem;
  font-family: PingFangSC, PingFangSC-Semibold;
  font-weight: 600;
  color: #ffffff;
  line-height: 0.44rem;
  margin: 0 auto;
}
</style>
