import request from "@/utils/request";

/**
 * 幸运大转盘首页信息
 * @returns
 */
export function luckIndex() {
  return request({
    url: "/activity/luck/index",
    method: "GET",
    headers: {
      needToken: true,
      loading: false,
      device: "APP",
    },
  });
}

/**
 * 抽奖
 * @returns
 */
export function luckDraw() {
  return request({
    url: "/activity/luck/draw",
    method: "GET",
    headers: {
      needToken: true,
      loading: false,
      device: "APP",
    },
  });
}
/**
 * 我的奖品列表 (带分页)
 * @param {*} data
 * {
 * pageNum: 当前页
 * pageSize: 每页条数
 * }
 * @returns
 */
export function luckUserList(data) {
  return request({
    url: "/activity/luck/user/list",
    method: "POST",
    headers: {
      needToken: true,
      loading: false,
      device: "APP",
    },
    data,
  });
}
